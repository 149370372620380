<template>
  <div class="flex min-h-screen flex-col">
    <TnHeader />
    <img
      v-if="canShowClouds"
      src="@/assets/cloud-left.svg"
      alt="Cloud Left"
      class="absolute left-0 top-[258px] hidden sm:block"
    />
    <img
      v-if="canShowClouds"
      src="@/assets/cloud-right-home.svg"
      alt="Cloud Right"
      class="absolute right-0 top-[259px] hidden sm:block"
    />
    <div class="flex-1">
      <slot />
    </div>
    <TnFooter class="mt-auto" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const router = useRouter();
const notify = useNotifications();

useHead({
  bodyAttrs: {
    class: 'bg-blue-400',
  },
});

const canShowClouds = computed(() => route.name === 'home');

const showEmailVerifiedNotification = () => {
  if (route.query.email_verified === 'true') {
    notify.showSuccessNotification({
      description: `Your email address has now been verified!`,
    });

    // Remove the query param to prevent the notification from showing again
    router.replace({
      query: {
        ...route.query,
        email_verified: undefined,
      },
    });
  }
};

onMounted(() => {
  showEmailVerifiedNotification();
});
</script>
